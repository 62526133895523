<template>
  <div class="d-flex flex-column flex-root">

    <template v-if="token">
      <Loader  v-bind:logo="loaderLogo"></Loader>
    </template>
    <template v-else>
      <div class="login login-4 d-flex flex-row-fluid animated " :class="activeFormClass" id="kt_login"
           style="min-height: 75rem;">
        <!--begin::Content-->
        <div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
             :style="{ backgroundImage: `url(${backgroundImage})` }">
          <div class="login-form text-center p-7 position-relative overflow-hidden">
            <!--begin::Login-->
            <!--begin::Login Header-->
            <div class="d-flex flex-center mb-15">
              <a href="#">
                <img src="../../../../public/media/logos/logo-letter-4.png" class="max-w-200px mb-n15" alt=""/>
              </a>
            </div>
            <!--end::Login Header-->
            <!--begin::Login Sign in form-->
            <div class="login-signin mt-20">
              <div class="mb-20">
                <h3>{{ $t('login.header') }}</h3>
                <div class="text-muted font-weight-bold">{{ $t('login.header_text') }}</div>
              </div>
              <b-form class="form animated animate__animated animate__backInUp" @submit.stop.prevent="onSubmit"
                      id="kt_login_signin_form">
                <div role="alert" v-bind:class="{ show: errors }" class="alert fade alert-danger">
                  <div class="alert-text">
                    {{ $t('login.invalid_user') }}
                  </div>
                </div>

                <div class="form-group mb-5">
                  <b-form-group
                      id="example-input-group-1"
                      label=""
                      label-for="example-input-1"
                  >
                    <b-form-input
                        :placeholder="$t('messages.email')"
                        class="form-control h-auto form-control-solid py-4 px-8 form-control-sm"
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ $t('login.email_error') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group mb-5">
                  <b-form-group
                      id="example-input-group-2"
                      label=""
                      label-for="example-input-2"
                  >
                    <b-form-input
                        :placeholder="$t('messages.password')"
                        class="form-control h-auto form-control-solid py-4 px-8 form-control-sm"
                        type="password"
                        id="example-input-2"
                        name="example-input-2"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                      {{ $t('login.password_error') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <div class="form-group d-flex flex-wrap justify-content-between align-items-right">
                  <a href="#" id="kt_login_forgot" class="text-muted text-hover-primary"
                     @click="showForm('forgot')">{{ $t('login.forgot_password') }} </a>
                </div>
                <button id="kt_login_signin_submit" ref="kt_login_signin_submit"
                        class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 btn-sm " @click="showForm('signin')">
                  {{ $t('messages.login') }}
                </button>
              </b-form>
            </div>
            <!--end::Login Sign in form-->
            <!--begin::Login forgot password form-->
            <div class="login-forgot">
              <div class="mb-20">
                <h3>{{ $t('login.forgot_password_header') }}</h3>
                <div class="text-muted font-weight-bold">{{ $t('login.forgot_password_header_text') }}</div>
              </div>
              <form class="form" id="kt_login_forgot_form" method="post">
                <div class="form-group mb-10">
                  <input class="form-control form-control-sm form-control-solid h-auto py-4 px-8" type="text"
                         :placeholder="$t('messages.email')" name="email" autocomplete="off"/>
                </div>
                <div class="form-group d-flex flex-wrap flex-center mt-10">
                  <button id="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2 btn-sm">
                    {{ $t('messages.send') }}
                  </button>
                  <button id="kt_login_forgot_cancel" type="button"
                          class="btn btn-light-primary btn-sm font-weight-bold px-9 py-4 my-3 mx-2 btn-sm "
                          @click="showForm('signin')">
                    {{ $t('messages.cancel') }}
                  </button>
                </div>
              </form>
            </div>
            <!--end::Login forgot password form-->
            <!--end::Login-->
          </div>
        </div>
        <!--end::Content-->
      </div>
    </template>
  </div>
</template>

<script>
import { mapState} from "vuex";
import {LOGIN, LOGOUT} from "@/core/services/store/auth.module";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
import KTUtil from "@/assets/js/components/util";
import moduleUser, {MODULE_NAME as MODULE_USER, GET_USER} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import SecureLS from "secure-ls";
import Loader from "@/view/content/Loader.vue";
import HtmlClass from "@/core/services/htmlclass.service";

const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

const _MODULE_USER = MODULE_USER;

export default {
  mixins: [validationMixin],
  name: "login",
  components: {
    Loader
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  data() {
    return {
      activeFormClass: 'login-signin-on',
      routeName: 'dashboard',
      extraParams: null,
      token: null,
      // Remove this dummy login info
      form: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    loginLogo() {
      return process.env.BASE_URL + "assets/media/logos/logo-letter-13.png";
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-3.jpg";
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  beforeMount() {
    let token = this.$route.query.token;
    if (token) {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // initialize html element classes
      HtmlClass.init(this.layoutConfig());
    }
  },
  methods: {
    showForm(id) {
      let form = 'kt_login_' + id + '_form';
      this.activeFormClass = 'login-' + id + '-on'
      KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getUser() {
      let self = this;
      this.$store.dispatch(_MODULE_USER + '/' + GET_USER)
          .then(result => {
            self.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            if (this.extraParams) {
              this.$router.push({name: this.routeName, params: {extraParam: this.extraParams }});
            } else {
              this.$router.push({name: this.routeName})
            }
          })
          .catch(error => {
            self.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const username = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let self = this;
      // send login request
      this.$store.dispatch(LOGIN, {username, password})
          // go to which page after successfully login
          .then((response) => {
            this.$store.dispatch(_MODULE_USER + '/' + GET_USER).then(res => {
              if (res) {
                let firstItem = _.head(_.values(res.menu))
                if (firstItem) {
                  this.$router.push({path: firstItem.menu.link})
                } else {
                  this.$router.push({name: "dashboard"})
                }
              }
            })
          })

      submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.routeName = this.$route.query.routeName;
    this.extraParams = this.$route.query.extraParams;
    if (this.token) {
      ls.set('token', this.token);
      this.getUser();
    } else {
      this.showForm('signin');
    }
  }
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/classic/login-4.scss";
</style>
